import * as React from 'react';
import './Tag.scss';
import { CssSize } from '@widgets/ClickableCard/types';
import Typography from '@widgets/Typography';
import { FontWeights } from '@widgets/Typography/Typography';

export type TagVariants = 'normal' | 'blue' | 'pill';

interface ITag {
  label: string | JSX.Element;
  variant?: TagVariants;
  backgroundColor?: string;
  textColor?: string;
  textSize?: number;
  height?: CssSize;
  fontWeight?: FontWeights;
}

const variantTextColorMap: Record<TagVariants, string> = {
  normal: '#121212',
  blue: '#2A68B6',
  pill: '#FFFFFF',
};

function Tag({
  label,
  variant = 'normal',
  backgroundColor,
  textColor,
  textSize,
  height = '24px',
  fontWeight = '500',
}: ITag) {
  return (
    <div
      className={`tag ${variant}`}
      style={backgroundColor ? { backgroundColor, height } : { height }}
    >
      <Typography
        size={textSize || 10}
        weight={fontWeight}
        withColor={textColor || variantTextColorMap[variant]}
      >
        {label}
      </Typography>
    </div>
  );
}

export default Tag;
