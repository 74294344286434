/* eslint-disable react/jsx-props-no-spreading */
import { StorageItems } from '@constants/App';
import { IUser } from '@customTypes/response/IUser';
import useAuth from '@hooks/useAuth';
import { getItemFromLocalStorage } from '@utilities/common/Storage';
import Button from '@widgets/Button';
import Loader from '@widgets/Loader';
import MultiSelectBox from '@widgets/MultiSelectBox/MultiSelectBox';
import SelectBox from '@widgets/SelectBox';
import TextArea from '@widgets/TextArea';
import TextBox from '@widgets/TextBox';
import Typography from '@widgets/Typography';
import * as React from 'react';
import { Controller, useForm } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useNavigate } from 'react-router-dom';
import { noWhiteSpaceRegex } from '../../../../OnboardingForm/constants';
import {
  useEditHelplineSession,
  useGetHelplineSessionDetails,
} from '../../hooks/useHelplineSession';
import './InPersonBookingRequest.scss';
import {
  HelplineCaseNoteSession,
  IInPersonBookingRequestFormTypes,
  RiskLevelLabels,
} from './types';

import { useHelplienSupportedOrganisations } from '../../hooks/useHelplienSupportedOrganisations';
import { useSendInPersonBookingRequest } from '../../hooks/useSendInPersonBookingRequest';
import { PrsentingIssues } from './constants';


const PresentingIssueOptions = PrsentingIssues?.flatMap((section) => section?.options) ?? [];

const defaultValue: IInPersonBookingRequestFormTypes = {
  name: '',
  phone: '',
  issue: '',
  secondaryIssue: [],
};

const InpersonFieldValidations = {
  required: {
    value: true,
    message: 'This is required.',
  },
  minLength: {
    value: 2,
    message: `Please enter a minimum of ${2} characters.`,
  },
  maxLength: {
    value: 1000,
    message: `Characters should not exceed more than ${1000} characters.`,
  },
  pattern: {
    value: noWhiteSpaceRegex,
    message: 'No trailing or leading spaces.',
  },
};

function InPersonBookingRequestForm() {
  const userData: IUser = getItemFromLocalStorage(
    StorageItems.USER_INFO,
    'object',
  ) as IUser;
  const { user } = useAuth();
  const navigate = useNavigate();

  const {
    control,
    formState: { isDirty, errors },
    watch,
    reset,
  } = useForm<IInPersonBookingRequestFormTypes>({
    mode: 'onChange',
    defaultValues: defaultValue,
  });

  const methods = useForm<{
    remarks: string;
    preferableTime: string;
  }>({
    mode: 'onChange',
    defaultValues: { remarks: '', preferableTime: '' },
  });

  const { organisation } = watch();

  const { data: organisations } = useHelplienSupportedOrganisations({
    token: user.token,
  });

  const SelectedOrg = React.useMemo(
    () =>
      organisation && +organisation !== -1
        ? organisations?.find((item) => item?.id === +organisation)
        : { name: 'Did not disclosed', id: -1 },
    [organisation, organisations],
  );

  const { mutate: sendRequest, isLoading: isSendRequestLoading } =
    useSendInPersonBookingRequest({
      onSuccess: () => {
        navigate('/');
      },
      token: user.token,
    });

  const handleSendRequest = () => {
    sendRequest({
      sessionId: userData.sessionId,
      organisationName: SelectedOrg?.name,
      preferableTime: methods.watch('preferableTime'),
      remarks: methods.watch('remarks'),
    });
  };

  const { mutate: updateSessionDetails, isLoading: isEditSessionLoading } =
    useEditHelplineSession({
      onSuccess: () => {
        handleSendRequest();
      },
      token: user.token,
    });

  const { isLoading } = useGetHelplineSessionDetails({
    sessionId: userData?.sessionId,
    onSuccess: (session: HelplineCaseNoteSession) => {
      reset({
        id: session?.id,
        name: session?.client?.name ?? '',
        email: session?.client?.email,
        phone: session?.client?.phone,
        organisation: session?.client?.organisation
          ? session.client.organisation.toString()
          : '',
        location: session?.client?.location,
        city: session?.client?.city,
        landmark: session?.client?.landmark,
        risk: session?.risk,
        issue: session?.issue,
        secondaryIssue: session?.secondaryIssue,
      });
    },
    token: user.token,
  });

  const handleSubmit = () => {
    if (isDirty) {
      updateSessionDetails({
        issue: watch('issue'),
        secondaryIssue: watch('secondaryIssue'),
        risk: watch('risk'),
        id: userData?.sessionId,
      });
    } else {
      handleSendRequest();
    }
  };

  const formChangedAndHavingErrors =
    isDirty && (Boolean(errors.issue) || Boolean(errors.secondaryIssue));

  if (isLoading) {
    return <Loader />;
  }

  return (
    <section className="inperson-form-container">
      <section className="header">
        <Typography size={24} weight="500">
          Fill the form to request an in-person session
        </Typography>
        <Typography size={14} weight="400" color="secondary" display="inline">
          Book a session for <b>{userData?.email}</b>
        </Typography>
      </section>

      <section className="request-form">
        <div>
          <label className="form-label" htmlFor="name">
            Caller Name
          </label>
          <Controller
            name="name"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { ref, ...field } }) => (
              <TextBox
                {...field}
                variant="box-border"
                maxLength={40}
                placeholder="Enter here"
                disabled
              />
            )}
          />
        </div>
        <div>
          <label className="form-label" htmlFor="phone">
            Phone Number
          </label>
          <Controller
            name="phone"
            control={control}
            rules={{ minLength: 5, required: true }}
            defaultValue=""
            render={({ field: { ref, ...field } }) => (
              <PhoneInput
                country="sg"
                specialLabel=""
                placeholder="Enter here"
                inputProps={{
                  name: 'contact',
                  required: true,
                  autoFocus: false,
                }}
                {...field}
                disabled
              />
            )}
          />
        </div>
        <div className="full-width">
          <label className="form-label" htmlFor="organisation">
            Organisation
          </label>
          <Controller
            name="organisation"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { ref, ...field } }) => (
              <TextBox
                {...field}
                value={SelectedOrg?.name ?? field?.value?.toString() ?? ''}
                variant="box-border"
                maxLength={40}
                placeholder="Enter here"
                disabled
              />
            )}
          />
        </div>
        <div className="full-width">
          <label className="form-label" htmlFor="email">
            Email
          </label>
          <Controller
            name="email"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { ref, ...field } }) => (
              <TextBox
                {...field}
                value={field.value ?? ''}
                variant="box-border"
                maxLength={40}
                placeholder="Enter here"
                disabled
              />
            )}
          />
        </div>
        <div>
          <label className="form-label" htmlFor="location">
            Country
          </label>
          <Controller
            name="location"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { ref, ...field } }) => (
              <TextBox
                {...field}
                value={field.value ?? ''}
                variant="box-border"
                maxLength={40}
                placeholder="Enter here"
                disabled
              />
            )}
          />
        </div>
        <div>
          <label className="form-label" htmlFor="city">
            City
          </label>
          <Controller
            name="city"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { ref, ...field } }) => (
              <TextBox
                {...field}
                value={field.value ?? ''}
                variant="box-border"
                maxLength={40}
                placeholder="Enter here"
                disabled
              />
            )}
          />
        </div>
        <div className="full-width">
          <label className="form-label" htmlFor="landmark">
            Landmark
          </label>
          <Controller
            name="landmark"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { ref, ...field } }) => (
              <TextBox
                {...field}
                value={field.value ?? ''}
                variant="box-border"
                maxLength={40}
                placeholder="Enter here"
              />
            )}
          />
        </div>
        <div className="full-width">
          <label className="form-label" htmlFor="issue">
            Primary presenting issue
            <span className="required-symbol">&#42;</span>
          </label>
          <Controller
            name="issue"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { ref, ...field } }) => (
              <SelectBox
                values={PresentingIssueOptions}
                selectedValue={field.value?.toString() || ''}
                fullWidth
                fontSize={14}
                placeholder="Presenting Issue"
                {...field}
              />
            )}
          />
          {errors?.issue?.message && (
            <Typography size={12} weight="500" color="error">
              {errors.issue?.message?.toString()}
            </Typography>
          )}
        </div>
        <div className="full-width">
          <label className="form-label" htmlFor="secondaryIssue">
            Secondary presenting issue (max 3)
            <span className="required-symbol">&#42;</span>
          </label>
          <Controller
            name="secondaryIssue"
            control={control}
            rules={{
              required: { value: true, message: 'This is required' },
            }}
            render={({ field: { ref, ...field } }) => (
              <MultiSelectBox
                values={PresentingIssueOptions}
                selectedValues={field.value || []}
                fullWidth
                fontSize={14}
                limitTags={3}
                {...field}
              />
            )}
          />
          {errors?.secondaryIssue?.message && (
            <Typography size={12} weight="500" color="error">
              {errors.secondaryIssue?.message?.toString()}
            </Typography>
          )}
        </div>
        <div className="full-width">
          <label className="form-label" htmlFor="risk">
            Risk Assessment Category
          </label>
          <Controller
            name="risk"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <TextBox
                {...field}
                value={field.value ? RiskLevelLabels?.[field.value] : ''}
                variant="box-border"
                maxLength={40}
                placeholder="Enter here"
                disabled
              />
            )}
          />
        </div>
        <div className="full-width">
          <label className="form-label" htmlFor="preferableTime">
            Preferred day/time (in the user&apos;s timezone)
            <span className="required-symbol">&#42;</span>
          </label>

          <Controller
            name="preferableTime"
            control={methods.control}
            render={({ field: { ref, ...field } }) => (
              <TextBox
                {...field}
                value={field.value ?? ''}
                variant="box-border"
                maxLength={40}
                placeholder="Enter date & time slots e.g., (23-Mar-25: 2PM-3PM, 4PM-5PM), (24-Mar-25: 2PM-3PM, 4PM-5PM)"
              />
            )}
            rules={InpersonFieldValidations}
          />
          {methods.formState.errors?.preferableTime?.message && (
            <Typography size={12} weight="500" color="error">
              {methods.formState.errors.preferableTime?.message?.toString()}
            </Typography>
          )}
        </div>
        <div className="full-width">
          <label className="form-label" htmlFor="remarks">
            Remarks
            <span className="required-symbol">&#42;</span>
          </label>
          <div>
            <Controller
              name="remarks"
              control={methods.control}
              render={({ field }) => (
                <TextArea
                  value={field.value ?? ''}
                  onChange={(val) => field.onChange(val)}
                  placeholder="Enter here"
                />
              )}
              rules={InpersonFieldValidations}
            />
            {methods.formState.errors?.remarks?.message && (
              <Typography size={12} weight="500" color="error">
                {methods.formState.errors.remarks?.message?.toString()}
              </Typography>
            )}
          </div>
        </div>
        <div className="full-width">
          <Typography size={14} weight="500">
            Note: You can update the remaining details directly within the
            helpline dashboard.
          </Typography>
        </div>
        <div className="full-width submit-btn">
          <Button
            label="Submit"
            onClick={handleSubmit}
            width="80%"
            disabled={
              !methods.watch('preferableTime') ||
              !methods.watch('remarks') ||
              Boolean(methods.formState.errors.preferableTime) ||
              Boolean(methods.formState.errors.remarks) ||
              isEditSessionLoading ||
              isLoading ||
              isSendRequestLoading ||
              formChangedAndHavingErrors
            }
          />
        </div>
      </section>
    </section>
  );
}

export default InPersonBookingRequestForm;
