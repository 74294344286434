export enum CoachFlow {
  Default = 0,
  SpecialCase = 1,
  F2F = 2,
  Error = 3,
  NoProviderToShow = 4,
  TnsNoProvidersToShow = 5,
  InPersonBookingRequest = 6,
}

export const tikTokCustomCountryFilterCities = [
  'Bangkok, Thailand',
  'Beijing, China',
  'Ho Chi Minh City, Vietnam',
  'Jakarta, Indonesia',
  'Kuala Lumpur, Malaysia',
  'Manila, Philippines',
  'Seoul, Korea',
  'Shanghai, China',
  'Singapore, Singapore',
  'Tokyo, Japan',
];

export enum MeetingTypes {
  VIRTUAL = '0',
  FACE_2_FACE = '1',
  IN_PERSON = '10',
}

export const MeetingTypeList = [
  {
    label: 'Virtual',
    value: MeetingTypes.VIRTUAL,
  },
  {
    label: 'Face-to-face',
    value: MeetingTypes.FACE_2_FACE,
  },
  {
    label: 'In-person (Offsite)',
    value: MeetingTypes.IN_PERSON,
  },
];

export enum ConfirmationModal {
  recurringSession = 1,
  selfRecurringSession = 2,
  NoModal = 0,
}
