import { ProviderRole } from '@customTypes/response/IChat';
import { AvailableSessionDurations } from '@customTypes/response/provider';

export enum ProviderAttributes {
  PROFESSIONAL_BACKGROUND = 0,
  AREA_OF_SPECIALTY = 1,
  MY_APPROACH = 2,
  LOCATION = 3,
}

export const ProviderRoles = {
  COACHING: '0',
  CLINICAL: '1',
  Nutritionist: '5',
  Fitness: '6',
  Financial: '7',
  INTELLECTCLINICOFFSITE: '10',
  ONSITE: '12',
};

export const unmatchReasonsData: {
  id: string;
  label: string;
}[] = [
  {
    id: '1',
    label: 'Want to use different language',
  },
  {
    id: '2',
    label: "Can't find suitable time slot",
  },
  {
    id: '3',
    label: 'Current provider is not helpful',
  },
  {
    id: '4',
    label: 'Current provider is not responsive',
  },
  {
    id: '5',
    label: 'Others',
  },
];

export const coachingSessionDurations: {
  label: string;
  value: AvailableSessionDurations;
}[] = [
  {
    label: '30 min',
    value: '1800',
  },
  {
    label: '60 min',
    value: '3600',
  },
];

export const clinicalSessionDurations: {
  label: string;
  value: AvailableSessionDurations;
}[] = [
  {
    label: '60 min',
    value: '3600',
  },
];

export const ProviderTags = {
  selfScheduled: 'Self Scheduled',
  helplineReferral: 'EAP Helpline',
};

export const VirtualProviderRoleItems = [
  {
    label: 'Coaching',
    value: '0',
  },
  {
    label: 'Clinical',
    value: '1',
  },
  {
    label: 'Nutritionist',
    value: '5',
  },
  {
    label: 'Fitness',
    value: '6',
  },
  {
    label: 'Financial',
    value: '7',
  },
];

export const InPersonProviderRoleItems = [
  // {
  //   label: 'Coaching',
  //   value: '0',
  // },
  {
    label: 'Clinical',
    value: '10',
  },
];

export const AllProvidersCountryId = -1;

export const providerCardPillLabel: Record<ProviderRole, string> = {
  '0': 'Your coach',
  '1': 'Your clinician',
  '12': 'Onsite',
};

export const ClinicRoomStatus = {
  NoSessionConflict: '1',
  RoomAvailableForSwap: '2',
  SessionConflict: '3',
} as const;
