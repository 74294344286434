export const RiskLevels = {
  Low: 0,
  Medium: 1,
  High: 2,
  HighCritical: 4,
  Other: 3,
} as const;

export const RiskLevelLabels = {
  [RiskLevels.Low]: 'Low',
  [RiskLevels.Medium]: 'Medium',
  [RiskLevels.High]: 'High: Non-critical',
  [RiskLevels.Other]: 'Other',
  [RiskLevels.HighCritical]: 'High: Critical',
};

export enum CaseNoteTypes {
  NOTE = 1,
  CALL_REASON = 2,
  CALL_DESCRIPTION = 3,
  RANGER_INTERVENTION = 4,
}

export interface IInPersonBookingRequestFormTypes {
  id?: string;
  email?: string;
  phone: string;
  name: string;
  organisation?: string;
  location?: string;
  city?: string;
  landmark?: string;
  issue: string;
  secondaryIssue: string[];
  risk?: (typeof RiskLevels)[keyof typeof RiskLevels];
}

export type IEditHelplineSessionDetails = Pick<
IInPersonBookingRequestFormTypes,
'risk' | 'issue' | 'secondaryIssue' | 'id'
>;

export type HelplineCaseNoteSession = {
  id: string;
  clientId: string;
  providerId: number;
  startTime: string;
  meetingId: string;
  meetingType: null | 'virtual' | 'f2f';
  risk: (typeof RiskLevels)[keyof typeof RiskLevels];
  endTime: string;
  issue: string;
  secondaryIssue: string[];
  caseNotes: CaseNote[];
  client: ClientInfoV2;
  sessionQuestionResponses: SessionQuestionResponse[];
  meeting: Record<string, any>;
  responderName: string;
  status: string;
  sessionType: string;
  meetingConsent: 'yes' | 'no';
  callStatus?: number | null;
  callAttempts?: number;
  callType?: number;
  escalationCallType?: string;
  outgoingCallType?: string;
  cispCallType?: string;
  clientPosition?: string;
  testCallType?: string;
};

export type ClientInfoV2 = {
  id: string;
  name: string;
  organisation: number;
  employeeId?: string;
  email: string;
  location: string;
  city?: string;
  landmark?: string;
  position?: string;
  age: string;
  tenure: string;
  phone: string;
  userId: string;
  isDependent?: boolean;
};

export type CaseNote = {
  addedBy: number;
  createdAt: string;
  updatedAt: string;
  id: string;
  note: string;
  sessionId: string;
  commentBy: string;
  attachments: CaseNoteAttachment[];
  noteType: CaseNoteTypes;
};

export type CaseNoteAttachment = {
  id: string;
  fileName: string;
  mimeType: string;
  size: number;
};

export type SessionQuestionResponse = {
  id: string;
  questionId: string;
  response: string;
  sessionId: string;
};

export type HelplineOrganisationListItem = {
  id: number;
  name: string;
  eapNote: string[];
  eapNoteCheatSheet?: string[];
  domain: string[];
  credits: Record<string, any>[];
};

export interface IInPersonBookingRequestPayloadType {
  sessionId: string;
  organisationName: string;
  preferableTime: string;
  remarks: string;
}
