import { range } from 'lodash';

export const ageOptions = [
  { label: 'Do not wish to disclose', value: 'no-disclose' },
  ...range(12, 65).map((val) => ({ label: String(val), value: String(val) })), // Age from 12 -> 64
  { label: '65+', value: '>=65' },
];

export const tenureOptions = [
  { label: '3-6 months', value: '3m-6m' },
  { label: '6-12 months', value: '6m-12m' },
  { label: '1-2 years', value: '1-2' },
  { label: '2-3 years', value: '2-3' },
  { label: '3-5 years', value: '3-5' },
  { label: '5+ years', value: '>=5' },
  { label: 'Do not wish to disclose', value: 'no-disclose' },
];

export const consentLocations = [
  'Shanghai, China',
  'Beijing, China',
  'Gurgaon, India',
  'Mumbai, India',
  'Tokyo, Japan',
  'Jakarta, Indonesia',
  'Kuala Lumpur, Malaysia',
  'Manila, Philippines',
  'Singapore, Singapore',
  'Seoul, South Korea',
  'Bangkok, Thailand',
  'Ho Chi Minh City, Vietnam',
] as const;

export const consentLocationCountryMap: Record<typeof consentLocations[number], string> = {
  'Bangkok, Thailand': 'TH',
  'Beijing, China': 'CN',
  'Gurgaon, India': 'IN',
  'Mumbai, India': 'IN',
  'Tokyo, Japan': 'JP',
  'Jakarta, Indonesia': 'ID',
  'Kuala Lumpur, Malaysia': 'MY',
  'Manila, Philippines': 'PH',
  'Singapore, Singapore': 'SG',
  'Seoul, South Korea': 'KR',
  'Ho Chi Minh City, Vietnam': 'VN',
  'Shanghai, China': 'CN',
};

export const consentCountries = [
  {
    label: 'Bangkok, Thailand',
    value: 'TH',
  },
  {
    label: 'Beijing, China',
    value: 'CN',
  },
  {
    label: 'Gurgaon, India',
    value: 'IN',
  },
  {
    label: 'Mumbai, India',
    value: 'IN',
  },
  {
    label: 'Tokyo, Japan',
    value: 'JP',
  },
  {
    label: 'Jakarta, Indonesia',
    value: 'ID',
  },
  {
    label: 'Kuala Lumpur, Malaysia',
    value: 'MY',
  },
  {
    label: 'Manila, Philippines',
    value: 'PH',
  },
  {
    label: 'Singapore, Singapore',
    value: 'SG',
  },
  {
    label: 'Seoul, South Korea',
    value: 'KR',
  },
  {
    label: 'Ho Chi Minh City, Vietnam',
    value: 'VN',
  },
  {
    label: 'Shanghai, China',
    value: 'CN',
  },
];

export const noWhiteSpaceRegex: RegExp = /^[^\s]+(?:$|.*[^\s]+$)/;
