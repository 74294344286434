/* eslint-disable @typescript-eslint/no-unused-vars */
import toast from 'react-hot-toast';
import { FetchKeys } from '@constants/queries';
import { PromiseCallbacks, useFetch, useMutation } from '@hooks/useFetch';
import { getHelplineSessionDetails, updateHelplineSessionDetails } from '@services/responder';
import { HelplineCaseNoteSession, IEditHelplineSessionDetails } from '../ProviderList/InPersonBookingRequest/types';

interface IGetHelplineSessionDetails extends PromiseCallbacks {
  sessionId: string;
  token: string;
}

interface IUpdateHelplineSessionDetails extends PromiseCallbacks {
  token: string;
}

export function useGetHelplineSessionDetails({
  sessionId,
  onSuccess,
  token,
}: IGetHelplineSessionDetails) {
  return useFetch<HelplineCaseNoteSession>(
    [FetchKeys.helplineSessionDetails, sessionId],
    () => getHelplineSessionDetails(sessionId, token),
    { enabled: Boolean(sessionId), onSuccess, refetchOnWindowFocus: false },
  );
}

export function useEditHelplineSession({ onSuccess, token }: IUpdateHelplineSessionDetails) {
  return useMutation(
    ({
      id,
      risk,
      issue,
      secondaryIssue,
    }: IEditHelplineSessionDetails) =>
      updateHelplineSessionDetails({ id, risk, issue, secondaryIssue }, token),
    {
      onSuccess: (_, variables) => {
        toast.success('Session edited successfully.');
        onSuccess?.(variables);
      },
      onError: (error: any) => {
        const apiError = error?.data?.error?.message;

        const errMsg =
          typeof apiError === 'string'
            ? apiError
            : 'An error occurred while editing this session.';

        toast.error(errMsg);
      },
    },
  );
}
