import { FetchKeys } from '@constants/queries';
import { PromiseCallbacks, useFetch } from '@hooks/useFetch';
import { getHelplineSupportedOrganisations } from '@services/responder';
import { HelplineOrganisationListItem } from '../ProviderList/InPersonBookingRequest/types';

interface IGetHelplineupportedOrganisations extends PromiseCallbacks {
  token: string;
}

export function useHelplienSupportedOrganisations({
  onSuccess,
  token,
}: IGetHelplineupportedOrganisations) {
  return useFetch<HelplineOrganisationListItem[]>(
    [FetchKeys.helplineSupportedOrganisations],
    () => getHelplineSupportedOrganisations(token),
    {
      onSuccess,
      // Org lists do not change frequently, this helps when the request is fetched multiple times
      cacheTime: 25 * 60 * 1000, // 25 minutes
      staleTime: 20 * 60 * 1000, // 20 minutes
    },
  );
}
