import * as React from 'react';
import './Tags.scss';
import { CssSize } from '@widgets/ClickableCard/types';
import { FontWeights } from '@widgets/Typography/Typography';
import Tag, { TagVariants } from '@widgets/Tag/Tag';

interface ITags {
  tags: string | string[];
  variant?: TagVariants;
  backgroundColor?: string;
  textColor?: string;
  textSize?: number;
  height?: CssSize;
  fontWeight?: FontWeights;
}

function Tags({
  tags,
  variant = 'normal',
  backgroundColor,
  textColor,
  textSize,
  height = '24px',
  fontWeight = '500',
}: ITags) {
  const tagItems = typeof tags === 'string' ? tags.split(',') : tags;
  return (
    <div className="tags-container">
      {tagItems.map((tag) => (
        <Tag
          key={tag}
          label={tag}
          backgroundColor={backgroundColor}
          fontWeight={fontWeight}
          height={height}
          textColor={textColor}
          textSize={textSize}
          variant={variant}
        />
      ))}
    </div>
  );
}

export default Tags;
