import { PromiseCallbacks, useMutation } from '@hooks/useFetch';
import toast from 'react-hot-toast';
import { sendInPersonBookingRequest } from '@services/responder';
import { IInPersonBookingRequestPayloadType } from '../ProviderList/InPersonBookingRequest/types';


interface ISendInPersonBookingRequest extends PromiseCallbacks {
  token: string;
}

export function useSendInPersonBookingRequest({
  onSuccess,
  token,
}: ISendInPersonBookingRequest) {
  return useMutation(
    (payload: IInPersonBookingRequestPayloadType) =>
      sendInPersonBookingRequest(payload, token),
    {
      onSuccess: (_, variables) => {
        toast.success('InPerson booking request sent successfully.');
        onSuccess?.(variables);
      },
      onError: (error: any) => {
        const apiError = error?.data?.error?.message;

        const errMsg =
          typeof apiError === 'string'
            ? apiError
            : 'An error occurred while editing this session.';

        toast.error(errMsg);
      },
    },
  );
}
