enum EnvType {
  Staging = 'staging',
  Production = 'production',
}

const EnvironmentVariables: any = {
  [EnvType.Staging]: {
    baseUrl: 'https://stage.internal.intellect.co/api/',
    imageServerUrl: 'https://d3imeoj536zhqj.cloudfront.net/images/',
    postSessionFeedbackLink: 'https://intellecthq.typeform.com/to/fPgWhI4D',
    websocket: 'https://socket-stage.internal.intellect.co/',
    irisUrl: 'https://stage.internal.intellect.co/api/iris/',
    helplineUrl: 'https://web.internal.intellect.co/',
  },
  [EnvType.Production]: {
    baseUrl: 'https://internal.intellect.co/api/',
    imageServerUrl: 'https://d3imeoj536zhqj.cloudfront.net/images/',
    postSessionFeedbackLink: 'https://intellecthq.typeform.com/to/bFjA0q1g',
    websocket: 'https://socket.internal.intellect.co/',
    irisUrl: 'https://internal.intellect.co/api/iris/',
    helplineUrl: 'https://app.intellect.co/',
  },
};
const { hostname } = window.location;
const Environment =
  EnvironmentVariables[
    hostname.includes('maverick.internal') ||
    hostname.includes('demo.internal') ||
    hostname.includes('localhost') ||
    hostname.includes('cloudfront.net') ||
    hostname.includes('s3-website-ap-southeast-1.amazonaws.com') ||
    hostname.includes('127.0.0.1')
      ? EnvType.Staging
      : EnvType.Production
  ];

const Config = {
  webSocket: `${Environment.websocket}websocket`,
  base: {
    admin: Environment.baseUrl,
    content: Environment.contentUrl,
  },
  api: {
    auth: {
      login: 'user/auth/provider/login',
      otpRequest: 'user/auth/otp/request',
      otpVerify: 'user/auth/otp/verify',
      self: 'user/auth/me',
      logout: 'user/auth/logout',
    },
    provider: {
      home: 'maverick/{?}/provider',
      list: 'maverick/{?}/provider/open',
      assign: 'maverick/{userId}/provider',
      slotsV1: 'maverick/{?}/provider/slots',
      slotsV2: 'maverick/v2/{?}/provider/slots',
      book: 'maverick/{?}/provider/book',
      match: 'maverick/{?}/provider',
      profile: 'coach/profile/{?}',
      cancelSession: 'meeting/{?}/cancel',
      confirmSessionRequest: 'meeting/{?}/confirm/',
      countriesList: '/maverick/{?}/providers/countries',
      matchConfig: 'coach/config',
      rescheduleMeeting: 'meeting/{?}/v2',
      cancellationReasons: 'meeting/cancellation/reasons',
      getLeaveDays: 'maverick/{?}/provider/leave',
      getServices: 'maverick/{?}/provider/services',
      getRoomsList: 'maverick/in-person/clinics/{?}/rooms',
    },
    user: {
      meetingDetails: 'coach/start',
      unmatch: 'coach/unassign',
      getProfile: 'user/profile',
      updateProfile: 'user/profile/update',
      updateProfileV2: 'user/profile/v2',
      userDetails: 'maverick/client-details/{?}',
      coachHome: 'coach/home',
      getTeams: 'app/organisation/team',
      updateTeams: 'app/organisation/v2/team/data',
    },
    meeting: {
      meetingDetails: 'meeting/{?}/join',
      requestF2FSession: 'maverick/f2f/request',
    },
    consent: {
      checkConsent: '/user/consent/check',
      updateConsent: '/user/consent',
      GDPRConsent: '/user/consent/gdpr',
    },
    media: {
      getImage: `${Environment.imageServerUrl}{?}`,
    },
    responder: {
      user: 'notebook/clients/users',
      session: 'notebook/sessions/{?}',
      updateSession: 'notebook/sessions/{?}/update',
      inPersonBookingRequest: 'notebook/sessions/inperson-request',
      organisations: 'notebook/clients/organisations',
    },
    careNavigator: {
      reschedule: '/maverick/{?}/meeting/{?}/reschedule',
      cancel: '/maverick/{?}/meeting/{?}/cancel',
      updateRoom: '/maverick/in-person/meetings/{?}/rooms',
    },
    ecpRelation: {
      addECP: '/user/ecp',
      deleteECP: '/user/ecp/{?}',
      getECP: '/user/ecp',
    },
    iris: {
      getIrisAccessToken: 'user/auth/serviceToken/iris',
      getIrisConversations: `${Environment.irisUrl}conversations`,
      sendIrisMessage: `${Environment.irisUrl}conversations/{?}/messages`,
      deleteIrisMessage: `${Environment.irisUrl}/conversations/:conversationId/messages/{?}`,
      getIrisMessages: `${Environment.irisUrl}conversations/{?}/messages?page={?}`,
    },
    chat: {
      setChatMessageConsumed: 'coach/chat/read/{?}',
      getAssignmentById: `${Environment.wisdomUrl}assignments/{?}`,
    },
    onsite: {
      eventList: 'meeting/onsite/deployments/live',
      locationList: 'meeting/onsite/country/locations',
      eventDetails: 'meeting/onsite/deployments/live/{?}',
      eventBook: 'meeting/onsite/meetings',
      upcomingSessions: 'meeting/onsite/meetings/list',
      rescheduleSlots: 'meeting/onsite/meetings/slots?onsiteId={?}',
      sessionReschedule: 'meeting/onsite/meetings/{?}',
      cancelSession: 'meeting/onsite/meetings/{?}/cancel',
    },
    personalInsights: {
      getResultList: 'wisdom/questionnaires/results?sessionId={?}',
      getResult: 'wisdom/questionnaires/results/{?}',
    },
  },
  misc: {
    postSessionFeedbackLink: Environment.postSessionFeedbackLink,
    externalHelpline: `${Environment.helplineUrl}clinician/matching/helpline`,
  },
};

export default Config;
