import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosRequestHeaders,
} from 'axios';
import Config from '../../config';
import { onRequestFulfilled, onRequestRejected } from './Request';
import { onResponseFulfilled, onResponseRejected } from './Response';
import { IUser } from '../../types/response/IUser';
import { getItemFromLocalStorage } from '../../../utilities/common/Storage';
import { APP_CONTEXT, APP_NAME, StorageItems } from '../../constants/App';
import { getCurrentLocale } from '../../../i18n';
import { getTimeZone } from '../../../utilities/common/Date';

class NetworkClient {
  client: AxiosInstance;

  constructor() {
    this.client = axios.create({
      baseURL: Config.base.admin,
      timeout: 60000,
    });
    this.attachCommonHeaders();
    this.attachInterceptors();
  }

  attachCommonHeaders = () => {
    this.client.defaults.headers.common.timezone = getTimeZone();
    this.client.defaults.headers.common.context = APP_CONTEXT;
    this.client.defaults.headers.common.language = getCurrentLocale();
    this.client.defaults.headers.common.source = APP_NAME;
    this.client.defaults.headers.common['Content-Type'] =
      'application/json; charset=utf-8';
  };

  attachInterceptors = () => {
    this.client.interceptors.request.use(onRequestFulfilled, onRequestRejected);
    this.client.interceptors.response.use(
      onResponseFulfilled,
      onResponseRejected,
    );
  };

  attachAuthToken = () => {
    // TODO to enhance performance - create custom event for listening local storage changes and put user object in memory cache rather than storage cache.
    const user: IUser = getItemFromLocalStorage(
      StorageItems.USER_INFO,
      'object',
    ) as IUser;

    if (user && user.token) {
      this.client.defaults.headers.common.Authorization = user.token;
    }
  };

  doGet = (
    url: string,
    headers?: Partial<AxiosRequestHeaders>,
    params?: {},
    abortSignal?: AbortSignal,
  ) => {
    this.attachAuthToken();
    const axiosConfig: any = {
      headers: { ...headers, language: getCurrentLocale(), timezone: getTimeZone() },
      params,
      abortSignal,
    };
    return this.client
      .get(url, axiosConfig)
      .then((response) => response.data)
      .catch((error) => error);
  };

  // TODO make params as an object instead of func args
  doPost = (
    url: string,
    body: Record<string, any> | FormData,
    headers?: Partial<AxiosRequestHeaders>,
    params?: {},
    progressCallback?: (progress: number) => void,
  ) => {
    this.attachAuthToken();
    const axiosConfig: AxiosRequestConfig = {
      params,
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round(
          // @ts-ignore
          (progressEvent.loaded * 100) / progressEvent.total,
        );
        progressCallback?.(percentCompleted);
      },
      // @ts-ignore
      headers: { ...headers, language: getCurrentLocale(), timezone: headers?.timezone || getTimeZone() },
    };

    return this.client
      .post(url, body, axiosConfig)
      .then((response) => response.data)
      .catch((error) => error.data);
  };

  // TODO clean syntax for post and put

  doPut = (
    url: string,
    body: Record<string, any> | FormData,
    headers?: AxiosRequestHeaders,
    params?: {},
    progressCallback?: (progress: number) => void,
  ) => {
    this.attachAuthToken();
    const axiosConfig: AxiosRequestConfig = {
      params,
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round(
          // @ts-ignore
          (progressEvent.loaded * 100) / progressEvent.total,
        );
        progressCallback?.(percentCompleted);
      },
      // @ts-ignore
      headers: { ...headers, language: getCurrentLocale(), timezone: getTimeZone() },
    };

    return this.client
      .put(url, body, axiosConfig)
      .then((response) => response.data)
      .catch((error) => error.data);
  };

  doPatch = (
    url: string,
    body: Record<string, any> | FormData,
    headers?: AxiosRequestHeaders,
    params?: {},
    progressCallback?: (progress: number) => void,
  ) => {
    this.attachAuthToken();
    const axiosConfig: AxiosRequestConfig = {
      params,
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round(
          // @ts-ignore
          (progressEvent.loaded * 100) / progressEvent.total,
        );
        progressCallback?.(percentCompleted);
      },
      // @ts-ignore
      headers: { ...headers, language: getCurrentLocale(), timezone: getTimeZone() },
    };

    return this.client
      .patch(url, body, axiosConfig)
      .then((response) => response.data)
      .catch((error) => error.data);
  };

  doDelete = (
    url: string,
    headers?: AxiosRequestHeaders,
    params?: {},
    progressCallback?: (progress: number) => void,
  ) => {
    this.attachAuthToken();
    const axiosConfig: AxiosRequestConfig = {
      params,
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round(
          // @ts-ignore
          (progressEvent.loaded * 100) / progressEvent.total,
        );
        progressCallback?.(percentCompleted);
      },
      // @ts-ignore
      headers: { ...headers, language: getCurrentLocale(), timezone: getTimeZone() },
    };

    return this.client
      .delete(url, axiosConfig)
      .then((response) => response.data)
      .catch((error) => error.data);
  };
}

export default NetworkClient;
