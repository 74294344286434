import * as React from 'react';
import Tag from '@widgets/Tag/Tag';
import { AnimatePresence, motion } from 'framer-motion';
import { CssSize } from '@widgets/ClickableCard/types';
import Typography from '../Typography';
import './MultiSelectBox.scss';
import chevronIcon from '../SelectBox/assets/placeholder.svg';
import tickIcon from '../SelectBox/assets/tick.svg';
import useOutsideClickHandler from '../../../utilities/hooks/useOutsideClickHandler';

interface IMultiSelectBox {
  id?: string;
  values: { label: string; value: string }[];
  onChange?: (selectedValues: string[]) => void;
  selectedValues: string[];
  disabled?: boolean;
  fontSize?: number;
  fullWidth?: boolean;
  placeholder?: string;
  minWidth?: CssSize;
  limitTags?: number;
}

function MultiSelectBox({
  id,
  values,
  onChange,
  selectedValues = [],
  disabled = false,
  fontSize = 12,
  fullWidth = false,
  placeholder = 'Select...',
  minWidth,
  limitTags,
}: IMultiSelectBox) {
  const parentRef = React.useRef<HTMLDivElement>(null);
  const [showItems, setShowItems] = React.useState<boolean>(false);
  const [searchTerm, setSearchItem] = React.useState<string>('');

  useOutsideClickHandler(parentRef, () => setShowItems(false));

  const toggleSelection = (value: string) => {
    let newSelected: string[] = [];
    if (selectedValues.includes(value)) {
      newSelected = selectedValues.filter((v) => v !== value);
    } else {
      newSelected = [...(selectedValues || [])];
      if (!limitTags || selectedValues?.length < limitTags) {
        newSelected.push(value);
      }
    }
    setSearchItem('');
    onChange?.(newSelected);
  };

  const filteredValues = values.filter(({ label }) =>
    label.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  const handleRemove = (item: string) => {
    onChange?.(selectedValues.filter((i) => i !== item));
  };

  const getStyles = () => ({
    minWidth,
  });

  return (
    <motion.div
      ref={parentRef}
      className={`multi-select-box ${disabled ? 'disabled' : ''}`}
      style={fullWidth ? { width: '100%' } : getStyles()}
    >
      <div
        id={id}
        className={`placeholder-container ${disabled ? 'disabled' : ''}`}
      >
        {!disabled && (
          <img className="chevron" src={chevronIcon} alt="select" />
        )}
        <div className="select-placeholder">
          {selectedValues.length > 0
            ? selectedValues?.map((value) => (
              <Tag
                label={
                  <>
                    {value}
                    <button
                      type="button"
                      style={{
                        background: 'transparent',
                        border: 'none',
                        color: 'white',
                        fontSize: 14,
                        cursor: 'pointer',
                      }}
                      onClick={() => handleRemove(value)}
                    >
                        x
                    </button>
                  </>
                }
                backgroundColor="darkgray"
                textColor="white"
                textSize={14}
              />
            ))
            : null}
          <input
            placeholder={placeholder || 'Search...'}
            style={{
              marginLeft: '4px',
              flex: 1,
              border: 'none',
              outline: 'none',
              width: '100%',
              fontSize: '14px',
            }}
            value={searchTerm}
            onChange={(e) => setSearchItem(e.target.value)}
            onClick={() => {
              if (!disabled) {
                setShowItems((prev) => !prev);
              }
            }}
          />
        </div>
      </div>
      <AnimatePresence>
        {showItems && (
          <motion.div
            className="v2-dropdown"
            style={fullWidth ? { width: '100%' } : getStyles()}
            initial={{ opacity: 0, top: '-70px' }}
            animate={{ opacity: 1, top: '50px' }}
            exit={{ opacity: 0, top: '-70px' }}
            transition={{ duration: 0.4 }}
          >
            {filteredValues.length > 0 ? (
              filteredValues.map(({ label, value }) => {
                const selected = selectedValues.includes(value);
                return (
                  <motion.div
                    key={value}
                    className={`dropdown-item ${selected ? 'selected' : ''}`}
                    role="button"
                    tabIndex={0}
                    onClick={() => toggleSelection(value)}
                    initial={{ backgroundColor: '#fff' }}
                    whileHover={{ backgroundColor: 'rgba(233, 236, 238, 0.5)' }}
                    animate={
                      selected ? { backgroundColor: 'rgb(233, 236, 238)' } : {}
                    }
                    transition={{ duration: 0.3 }}
                  >
                    <Typography size={fontSize} weight="400">
                      {label}
                    </Typography>
                    {selected && (
                      <motion.img
                        src={tickIcon}
                        alt="selected"
                        initial={{ scale: 0 }}
                        animate={{ scale: 1 }}
                      />
                    )}
                  </motion.div>
                );
              })
            ) : (
              <motion.div className="dropdown-item">
                <Typography size={fontSize} weight="400" color="secondary">
                  No results found
                </Typography>
              </motion.div>
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
}

export default MultiSelectBox;
